import React from "react"
import { Header, Footer } from "@lib/simba/_statics"
import { graphql } from "gatsby"
import Layout from "@utils/layout"
import Divbox from "@lib/simba/divbox"
import Seo from "@components/Seo"
import getSeo from "@utils/get-seo"
import { WEBSITE } from "@utils/get-schemas"
import { Element, Grid, GridElement, GridContainer } from '@lib/simba/home-grid'

const Index = ({ data }) => (
  <Layout>
    <Seo seo={getSeo({
            ...data.sanityIdentity, 
            url: data.site.siteMetadata.siteUrl
        }, WEBSITE)} />

    <Divbox>
      <Header front={true} shape />
    </Divbox>

    <GridContainer>
        <Grid>
          {data.grid.nodes.map((el, i) => (
            <GridElement
              turn={i}
              data-sal={i === 0 ? 'slide-right' : 'slide-up' }
              data-sal-duration={1000}
              data-sal-easing='easeOutBack'
            >
              <Element
                  background={el.cover}
                  title={el.title}
                  description={el.resume}
                  link={false}
                  slug={el.slug}
              />
            </GridElement>
          ))}
        </Grid>        
    </GridContainer>
    <Footer />
  </Layout>
)

export const query = graphql`
    query ContentQuery {
        sanityIdentity {
            seo {
                title
                description
                keywords
            }
        }
        site {
            siteMetadata {
                clientToken
                siteUrl
                title
            }
        }
        grid: allSanityContenu(
          filter: {categories: {elemMatch: {slug: {current: {eq: "nos-metiers"}}}}}
          sort: {
            fields: [order]
            order: ASC
          }
        ) {
          nodes {
            slug {
              current
            }
            __typename
            title
            resume
            cover {
              asset {
                fluid(maxWidth: 1280) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
    }
`

export default Index
