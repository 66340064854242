import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

const Container = styled(BackgroundImage)`
    width: 100%;
    height: 100%;
    display: flex;
    padding: ${({ theme }) => theme.paddingBox};
    margin-bottom: ${({ theme }) => theme.paddingBox};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        margin-bottom: 0;
    }
`

const Zone = styled.div`
    padding: ${({ theme }) => theme.paddingBox};
    background: ${({ theme }) => theme.header.background};
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        max-width: 80%;
    }
`

const StyledLink = styled(Link)`
    align-self: flex-end;
`

const StyledDiv = styled.div`
    align-self: flex-end;
`

const Title = styled.h2`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.paddingBox};
    position: relative;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    white-space: break-spaces;
    font-size: 5vw;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.5vw;
    }
    &:after {
        position: absolute;
        content: '';
        height: 1px;
        width: 60px;
        background: white;
        left: 0; bottom: -10px;
    }
`

const Description = styled.p`
    line-height: 1.33;
    color: white;
    margin: 0;
    font-size: 4vw;
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1vw;
    }
`

const LinkContainer = ({ link, children }) => {
    const Element = link ? StyledLink : StyledDiv
    return <Element to={link}>{children}</Element>
}

const Element = ({ background, title, description, link, slug }) => (
    <Link href={`/${slug.current}`}>
        <Container fluid={background.asset.fluid}>
            <LinkContainer link={link}>
                <Zone>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </Zone>
            </LinkContainer>
        </Container>
    </Link>
)

export default Element