import styled from 'styled-components'

export const GridElement = styled.div`
    grid-row: ${({ turn }) => turn === 0 ? '1/4' : turn};
`

export default styled.div`
    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        display: grid;
        row-gap: ${({ theme }) => theme.paddingBox};
        column-gap: ${({ theme }) => theme.paddingBox};
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
    }
`